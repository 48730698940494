import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarmserver to link your Cameras Alarm",
  "path": "/Frequently_Asked_Question/AS_Alarm_Link_between_Cameras/",
  "dateChanged": "2021-01-26",
  "author": "Mike Polinowski",
  "excerpt": "I have several INSTAR cameras in operation. Now only one 9020 has an alarm siren. Is it possible that when the motion sensor of the other 9020 is triggered, the alarm of both cameras is activated?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Alarmserver to link your Cameras Alarm' dateChanged='2021-01-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='I have several INSTAR cameras in operation. Now only one 9020 has an alarm siren. Is it possible that when the motion sensor of the other 9020 is triggered, the alarm of both cameras is activated?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/AS_Alarm_Link_between_Cameras/' locationFR='/fr/Frequently_Asked_Question/AS_Alarm_Link_between_Cameras/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "alarmserver-to-link-your-cameras-alarm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#alarmserver-to-link-your-cameras-alarm",
        "aria-label": "alarmserver to link your cameras alarm permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarmserver to link your Cameras Alarm`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I have several INSTAR cameras in operation. Now only one 9020 has an alarm siren. Is it possible that when the motion sensor of the other 9020 is triggered, the alarm of both cameras is activated?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Yes, the easiest way to do this is via the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`alarm server of the camera`}</a>{`. If you add the IP of the other camera in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`alarm server settings`}</a>{` of the first camera with the following command:`}</p>
    <blockquote>
      <p parentName="blockquote">{`/param.cgi?cmd=pushhostalarm`}</p>
    </blockquote>
    <p>{`an alarm on the first camera would automatically trigger an alarm on the other camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/897b07e8eda0114f8844aa6600353936/78597/Alarmserver.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC20lEQVQ4y32TS28bZRSG/TtAiKQCeeykcW4OCrSyZ8b2eGacuV/sudmxHbEk0AVtQZVYsGcFYpOCLCE2/MYHfd900hTRLl6dM5L96H3POV/r6OiIw8NDDg4OZO12u3Q6Hfb2P2W0/Rn3h3+YPtsx+naH+s0O7fZdqbc79Nsd3ou/uXp2R6uBCVADa3c7HH/yiOT6FcVPf5G9vGP+8g+SF69JZa37ez2/o3z1J/n3v9FqQAIqYB1F4bOuwuQjhezpGGdREqQF+XJLVm3IqzX5ci37TPYbwrTAjUsc16PVuHsI/LyroH2sUOgzqu2G1XLFIsvwfZ8gSonSgiiOCZNUKhB9ucVfXNfABqooSh1ZaXP+uEfguORFQVEUJEmC73t4YYITzHGdKxLLIjYtkjeKZ/ZboADdA9ttjk9P8HxfwtI0JQxDgiCUIFVXGX35FdlZn/ysT3XWZ3l6Tq7r73HYbnNycoLnuiwWCwlzXVdGFtW0LCbjMUNVZahpUgNNY2aZHwYKQFVVlGUpa57nRFFEEATvyPM81pstRZq+PZv7pTSRj4/lD7Msk5rP53KOcRxLiAALhVFI6PlsVwVpFtISgIcuG2Cv15PA1WolJWDiW8i2bQzDkJqaJoHtsAxN7NykJWI2Lh86FC9IzKtxWG/Zl7Jsm6mAGQaWaWLPZnLOkevWwP+bYQMUsxNA8Qd5h0Eg+zCO8OMY780IFuWacF7VwOa1NECl3ebwqCcvvywKOTcRtVmAYZo4msZiqDIfqkw1FXViYjlBDfyvBHR//xETw5AORdz6DgNcx+GJqmJffMFN75T09Bytf8HFRR9jPKqX0ughcG9vj9FoJO9QAMVGReR6KRaGPsQYPkEdazw1DAbGhKltf8jhPpPJ5P6VNGcSRSFxmjH/7hfmP77mZvOcX+0rfvdivp4573cogJeXlxKq67p0K6Xr6KMJY79iHN9gWSHXgwFrVcceDPgXVYRpbpfAcGwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/897b07e8eda0114f8844aa6600353936/e4706/Alarmserver.avif 230w", "/en/static/897b07e8eda0114f8844aa6600353936/d1af7/Alarmserver.avif 460w", "/en/static/897b07e8eda0114f8844aa6600353936/7f308/Alarmserver.avif 920w", "/en/static/897b07e8eda0114f8844aa6600353936/3d505/Alarmserver.avif 947w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/897b07e8eda0114f8844aa6600353936/a0b58/Alarmserver.webp 230w", "/en/static/897b07e8eda0114f8844aa6600353936/bc10c/Alarmserver.webp 460w", "/en/static/897b07e8eda0114f8844aa6600353936/966d8/Alarmserver.webp 920w", "/en/static/897b07e8eda0114f8844aa6600353936/19b8a/Alarmserver.webp 947w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/897b07e8eda0114f8844aa6600353936/81c8e/Alarmserver.png 230w", "/en/static/897b07e8eda0114f8844aa6600353936/08a84/Alarmserver.png 460w", "/en/static/897b07e8eda0114f8844aa6600353936/c0255/Alarmserver.png 920w", "/en/static/897b07e8eda0114f8844aa6600353936/78597/Alarmserver.png 947w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/897b07e8eda0114f8844aa6600353936/c0255/Alarmserver.png",
              "alt": "Alarmserver zum Verknüpfen Ihrer Kamera Alarme",
              "title": "Alarmserver zum Verknüpfen Ihrer Kamera Alarme",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Authentication`}</strong>{`: Note that you have to add the admin login to your second camera in the dialogue above.`}</p>
    <br /><br />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      